import { IconButton, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { IAvailableAttendance, IOrder } from '@vacinas-net/shared'
import { keyBy } from 'lodash'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  Record,
  RecordMap,
  TextField,
  useNotify,
} from 'react-admin'
import { apiUrl } from '../../../../components/AppDataProvider'
import customHttpClient from '../../../../components/CustomHttpClient'
import { parseAddress } from '../../../../utils/utils'
import AvailableAttendanceOrderField from './AvailableAttendanceOrderField'
import StatusToogleField from './StatusToogleField'
import { mainTheme } from '../../../../utils/mainTheme'
import { format } from 'date-fns'

const AvailableAttendancesTable = (props: {
  availableAttendances?: IAvailableAttendance[]
  setAttendanceToEdit: (attendance: IAvailableAttendance) => void
  setOpenAvailableAttendanceModal: (state: boolean) => void
}) => {
  const availableAttendances = props.availableAttendances || []
  const { setAttendanceToEdit, setOpenAvailableAttendanceModal } = props
  const [sort, setSort] = useState<{
    field: string
    order: string
  }>({ field: 'startDatetime', order: 'ASC' })
  const [patchLoading, setPatchLoading] = useState(false)
  const [hasOrder, setHasOrder] = useState(
    Object.fromEntries(availableAttendances.map(({ _id }) => [_id, false]))
  )
  const [checkOrderLoading, setCheckOrderLoading] = useState(false)
  const notify = useNotify()

  const checkIfHasOrder = async () => {
    if (!availableAttendances.length) return

    setCheckOrderLoading(true)

    const result = await Promise.allSettled(
      availableAttendances.map(async (availableAttendance) => {
        const filter = qs.stringify({
          ['status!']: 'cancelled',
          availableAttendanceId: availableAttendance._id,
          page: 0,
          limit: 1,
        })

        const result = await customHttpClient(`${apiUrl}/order?${filter}`, {
          method: 'GET',
        })
        const body = result.json as { total: number; results: IOrder[] }

        return [availableAttendance._id, body.total > 0]
      })
    )

    if (result.some((result) => result.status === 'rejected'))
      notify('Erro ao buscar pedidos', { type: 'error' })

    const newHasOrder = result
      .filter((item) => item.status !== 'rejected')
      .map((item) => {
        const fulfilledItem = item as PromiseFulfilledResult<
          (string | boolean)[]
        >
        return fulfilledItem.value
      })

    setHasOrder({
      ...hasOrder,
      ...Object.fromEntries(newHasOrder),
    })

    setCheckOrderLoading(false)
  }

  const formatAvailableAttendancePeriod = (
    startDatetime: string,
    endDatetime: string
  ) => {
    return `${format(new Date(startDatetime), 'HH:mm')} - ${format(
      new Date(endDatetime),
      'HH:mm'
    )}`
  }

  useEffect(() => {
    checkIfHasOrder()
  }, [])

  if (!availableAttendances.length) {
    return (
      <div style={{ width: '100%' }}>
        <Typography
          color="textPrimary"
          variant="body1"
          style={{ textAlign: 'center', marginTop: mainTheme.spacing(2) }}
        >
          Ainda não foi criado nenhum formulário de agendamento para esta
          campanha
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Datagrid
        data={
          keyBy(availableAttendances, '_id') as unknown as RecordMap<Record>
        }
        ids={availableAttendances.map((attendance) => attendance._id)}
        currentSort={sort!}
        setSort={(field, order) =>
          setSort({ field: field, order: String(order) })
        }
        style={{ marginTop: mainTheme.spacing(2) }}
      >
        <DateField
          locales="pt-BR"
          textAlign="center"
          source="startDatetime"
          label="Data"
          emptyText="-"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.address && parseAddress(record.address)
          }
          label="Local"
          source="address"
          sortable={false}
        />
        <TextField
          textAlign="center"
          source="clinic.name"
          label="Clínica responsável"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <Typography color="textPrimary" variant="body2">
              {formatAvailableAttendancePeriod(
                record?.startDatetime,
                record?.endDatetime
              )}
            </Typography>
          )}
          label="Período"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => {
            const availableAttendance =
              record as unknown as IAvailableAttendance
            return (
              <AvailableAttendanceOrderField
                availableAttendance={availableAttendance}
                hasOrders={hasOrder[availableAttendance._id]}
              />
            )
          }}
          label="Pedidos"
          sortable={false}
        />{' '}
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => {
            const queryString = qs.stringify({
              availableAttendanceUuid: record?.uuid,
            })
            return (
              <Link
                to={{
                  pathname: '/register-vacineshot/signin',
                  search: queryString,
                }}
                target="blank"
              >
                <Typography
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  color="textPrimary"
                  variant="body2"
                >
                  Acessar link de aplicação
                </Typography>
              </Link>
            )
          }}
          label="Link de aplicação"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setAttendanceToEdit(record as unknown as IAvailableAttendance)
                setOpenAvailableAttendanceModal(true)
              }}
            >
              <Edit />
            </IconButton>
          )}
          label="Editar"
          sortable={false}
        />
        <FunctionField
          label="Ativar/Desativar"
          textAlign="center"
          render={(record: Record | undefined) => {
            const availableAttendance =
              record as unknown as IAvailableAttendance
            return (
              <StatusToogleField
                availableAttendance={availableAttendance}
                hasOrder={hasOrder[availableAttendance._id]}
                checkOrderLoading={checkOrderLoading}
                patchLoading={patchLoading}
                setPatchLoading={(value) => setPatchLoading(value)}
              />
            )
          }}
          align="center"
          sortable={false}
        />
      </Datagrid>
    </>
  )
}

export default AvailableAttendancesTable
