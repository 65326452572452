import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Grid,
  Button,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ReactInputMask from 'react-input-mask'
import { mainTheme } from '../../../../utils/mainTheme'
import customHttpClient from '../../../../components/CustomHttpClient'
import { apiUrl } from '../../../../components/AppDataProvider'
import { useListContext, useNotify, useRecordContext } from 'react-admin'
import { ICampaignV2 } from '@vacinas-net/shared'
import { isValidCPF } from '@brazilian-utils/brazilian-utils'
import qs from 'query-string'

export interface AllowedCustomerFormData {
  fullName?: string
  document?: string
  email?: string
  givenVoucherAmount?: number
}

const schema = yup.object().shape({
  fullName: yup.string(),
  document: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Esse campo deve conter um email válido'),
  givenVoucherAmount: yup.number().required('Campo obrigatório'),
})

const AddSingleAllowedCustomerFormModal = (props: {
  open: boolean
  loading: boolean
  setOpen: (state: boolean) => void
  setLoading: (state: boolean) => void
}) => {
  const { open, setOpen, setLoading, loading } = props
  const notify = useNotify()
  const record = useRecordContext() as ICampaignV2
  const list = useListContext()

  const createAllowedCustomer = async () => {
    const parsedDocument = values.document!.replace(/\D/g, '')

    if (!isValidCPF(values.document!)) {
      setErrors({
        ...errors,
        document: 'CPF inválido',
      })
      return
    }

    if (values.fullName?.charAt(0) === ' ') {
      setErrors({
        ...errors,
        fullName: 'Nome inválido',
      })
      return
    }
    try {
      setLoading(true)
      const queryString = qs.stringify({
        document: parsedDocument,
        campaignId: record._id,
      })

      const allowedCustomerResponse = await customHttpClient(
        `${apiUrl}/campaign/allowed-customer?${queryString}`,
        {
          method: 'get',
        }
      )

      if (allowedCustomerResponse.json.total) {
        setErrors({
          ...errors,
          document: 'CPF já cadastrado',
        })
        return
      }

      await customHttpClient(
        `${apiUrl}/campaign/allowed-customer-batch/individual`,
        {
          method: 'post',
          body: JSON.stringify({
            campaignId: record._id,
            allowedCustomer: {
              fullName: values.fullName || undefined,
              document: parsedDocument,
              email: values.email || undefined,
              givenVoucherAmount: values.givenVoucherAmount,
            },
          }),
        }
      )
      resetForm()
      list.refetch()
      setOpen(false)
    } catch (error) {
      notify(`Erro ao inserir beneficiário: ${error}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const { values, setFieldValue, handleSubmit, errors, setErrors, resetForm } =
    useFormik<AllowedCustomerFormData>({
      initialValues: {},
      onSubmit: createAllowedCustomer,
      validationSchema: schema,
      validateOnChange: false,
    })

  const shouldDisableConfirmButton = () => {
    return !values.givenVoucherAmount || !values.document || loading
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Adicionar beneficiário</DialogTitle>
      <DialogContent>
        <Typography
          style={{ marginBottom: mainTheme.spacing(2) }}
          variant="body1"
        >
          Preencha as informações abaixo e confirme para incluir um beneficiário
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome"
                variant="outlined"
                value={values?.fullName}
                onChange={(ev) => {
                  setFieldValue('fullName', ev.target.value.replace(/\d/g, ''))
                  setErrors({
                    ...errors,
                    fullName: '',
                  })
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.fullName)}
                inputProps={{
                  'data-testid': 'fullName-input',
                }}
              />
              {errors.fullName && (
                <Typography variant="body2" color="error">
                  {errors.fullName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <ReactInputMask
                mask="999.999.999-99"
                disabled={false}
                value={values.document}
                onChange={(ev) => {
                  setFieldValue('document', ev.target.value)
                  setErrors({
                    ...errors,
                    document: '',
                  })
                }}
              >
                {() => (
                  <TextField
                    size="small"
                    variant="outlined"
                    label="CPF*"
                    error={Boolean(errors.document)}
                    inputProps={{
                      'data-testid': 'document-input',
                    }}
                  />
                )}
              </ReactInputMask>
              {errors.document && (
                <Typography variant="body2" color="error">
                  {errors.document}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: mainTheme.spacing(1) }}
            container
            spacing={2}
            xs={12}
          >
            <Grid item xs={6}>
              <TextField
                type="email"
                fullWidth
                label="E-mail"
                variant="outlined"
                value={values?.email}
                onChange={(ev) => {
                  setFieldValue('email', ev.target.value)
                  setErrors({
                    ...errors,
                    email: '',
                  })
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.email)}
                inputProps={{
                  'data-testid': 'email-input',
                }}
              />
              {errors.email && (
                <Typography variant="body2" color="error">
                  {errors.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                fullWidth
                label="Quantidade de vales*"
                variant="outlined"
                value={values?.givenVoucherAmount}
                onChange={(ev) => {
                  setFieldValue('givenVoucherAmount', parseInt(ev.target.value))
                  setErrors({
                    ...errors,
                    givenVoucherAmount: '',
                  })
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.givenVoucherAmount)}
                inputProps={{
                  'data-testid': 'givenVoucherAmount-input',
                  min: 1,
                }}
              />
              {errors.givenVoucherAmount && (
                <Typography variant="body2" color="error">
                  {errors.givenVoucherAmount}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: mainTheme.spacing(1),
              marginBottom: mainTheme.spacing(1),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            container
            spacing={2}
            xs={12}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ marginLeft: mainTheme.spacing(1) }}
              variant="contained"
              type="submit"
              color="primary"
              disabled={shouldDisableConfirmButton()}
            >
              Confirmar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddSingleAllowedCustomerFormModal
