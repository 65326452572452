import React, { useEffect, useState } from 'react'
import {
  GetListParams,
  InputProps,
  useDataProvider,
  useInput,
  useListContext,
  useNotify,
} from 'react-admin'
import CustomMultiAutocomplete from '../../CustomMultiAutocomplete'
import { OptionType } from '../../CustomAutocomplete'
import qs from 'query-string'

type CustomInputProps = InputProps & {
  source: string
  resourceToFetch: string
  readOnly?: boolean
  label?: string
  optionTypeLabel?: string
  optionTypeValue?: string
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  size?: 'small' | 'medium' | undefined
  style?: React.CSSProperties
  parseLabel?: (itemToParse: string) => string
  parseFilterToRequest?: (filter: string) => string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultFilters?: Record<string, any>
}

const MultiAutocompleteInput = (props: CustomInputProps) => {
  const [choosenOptions, setChoosenOptions] = useState<OptionType[]>([])
  const { input } = useInput(props)
  const optionTypeLabel = props.optionTypeLabel || 'name'
  const optionTypeValue = props.optionTypeValue || '_id'
  const { filterValues } = useListContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const fetchInitialData = async () => {
    const itemsToFetch: string[] = filterValues[props.source]
    const optionTypeArray: OptionType[] = []

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {}
    filter[optionTypeValue] = itemsToFetch

    const params: GetListParams = {
      filter: filter,
      pagination: {
        page: 1,
        perPage: itemsToFetch.length,
      },
      sort: {
        field: props.optionTypeLabel!,
        order: 'asc',
      },
    }

    await dataProvider
      .getList(props.resourceToFetch, params)
      .then(({ data }) => {
        data.forEach((item) => {
          optionTypeArray.push({
            label: props.parseLabel
              ? props.parseLabel(item[optionTypeLabel])
              : item[optionTypeLabel],
            value: item[optionTypeValue],
          })
        })
      })
      .catch((error) => {
        notify(
          `Error while fetching ${props.resourceToFetch} data: ${error}`,
          'error'
        )
      })

    setChoosenOptions(optionTypeArray)
  }

  useEffect(() => {
    if (filterValues[props.source]) {
      fetchInitialData()
    }
  }, [])

  return (
    <CustomMultiAutocomplete
      currentValueArray={choosenOptions}
      onChange={(value) => {
        setChoosenOptions(value)
        input.onChange(value.map((obj) => obj.value))
      }}
      resource={props.resourceToFetch}
      optionTypeLabel={optionTypeLabel}
      optionTypeValue={optionTypeValue}
      label={props.label}
      size={props.size}
      variant={props.variant}
      readOnly={props.readOnly}
      style={props.style}
      parseFilterToRequest={props.parseFilterToRequest}
      parseLabel={props.parseLabel}
      defaultFilters={qs.stringify(props.defaultFilters!)}
    />
  )
}

export default MultiAutocompleteInput
