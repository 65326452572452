import {
  Typography,
  CircularProgress,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { mainTheme } from '../utils/mainTheme'
import { apiUrl } from '../components/AppDataProvider'
import { CustomerAvailableCampaignForDoseRegisterDTO } from '@vacinas-net/shared'
import qs from 'query-string'
import { useHistory } from 'react-router-dom'
import { useNotify } from 'react-admin'
import AvailableAttendanceFormHeader from '../components/RegisterVaccineShotComponents/AvailableAttendanceFormHeader'
import SignInForm from '../components/RegisterVaccineShotComponents/SignInForm'

export const useStyles = makeStyles({
  campaignInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: mainTheme.spacing(1),
    [mainTheme.breakpoints.down('sm')]: {
      marginBottom: mainTheme.spacing(2),
    },
  },
  registerVaccineShotContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: mainTheme.spacing(6),
    padding: mainTheme.spacing(2),
  },
})

const RegisterVaccineShot = () => {
  const classes = useStyles()
  const history = useHistory()
  const notify = useNotify()

  const [loading, setLoading] = useState(true)

  const [campaignData, setCampaignData] =
    useState<CustomerAvailableCampaignForDoseRegisterDTO>()

  const attendance = campaignData?.availableAttendance

  const startDateTime = attendance && new Date(attendance.startDatetime)
  const endDateTime = attendance && new Date(attendance.endDatetime)
  const params = qs.parse(history.location.search.replace('?', ''))

  const fetchAttendanceData = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${apiUrl}/campaign/available-attendance/${params.availableAttendanceUuid}/external`,
        {
          method: 'get',
        }
      )

      const parsedContent = await response.json()
      setCampaignData(parsedContent)
    } catch (error) {
      console.error(error)
      notify(`Erro ao obter dados da campanha: ${error}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAttendanceData()
  }, [])

  if (loading)
    return (
      <ThemeProvider theme={mainTheme}>
        <div className={classes.registerVaccineShotContainer}>
          <CircularProgress color="inherit" size={20} />
        </div>
      </ThemeProvider>
    )

  return (
    <ThemeProvider theme={mainTheme}>
      <div className={classes.registerVaccineShotContainer}>
        <div>
          <AvailableAttendanceFormHeader
            address={attendance?.address}
            camapaignName={campaignData?.name}
            clinicName={attendance?.clinic.name}
            endDatetime={endDateTime}
            startDatetime={startDateTime}
            totalAvailableAttendances={
              campaignData?.availableAttendance?.appointmentsTotal
            }
          />
          <div style={{ marginTop: mainTheme.spacing(2) }}>
            <Typography color="primary" variant="h1">
              Registro de doses
            </Typography>

            {campaignData?._id && (
              <SignInForm
                campaignId={campaignData._id}
                availableAttendanceId={String(attendance?._id)}
                campaignData={campaignData}
              />
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default RegisterVaccineShot
