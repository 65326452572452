import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'

import firebase from 'firebase/app'
import { useNotify } from 'ra-core'
import { mainTheme } from '../../utils/mainTheme'
import { translateFirebaseError } from '../../utils/utils'

export default function AlertDialog() {
  const notify = useNotify()

  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')

  const handleSubmit = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email)

      notify('E-mail para redefinir senha enviado!')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notify(translateFirebaseError(error.code))
    } finally {
      setOpen(false)
    }
  }

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const email = event.target.value
    setEmail(email)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: mainTheme.spacing(1),
      }}
    >
      <Button
        variant="text"
        style={{
          color: mainTheme.palette.success.main,
          textDecoration: 'underline',
        }}
        onClick={() => setOpen(true)}
      >
        Esqueci a senha
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Redefinir senha?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Um E-mail para redefinir sua senha será enviado ao E-mail:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="E-mail"
            type="email"
            variant="outlined"
            style={{ width: '100%' }}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Enviar E-mail
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
